import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { fetchImageThunk } from "../../store/thunks/repliersThunk";
import ImageCarousel from "./ImageCarousel";

const PropertyGridTile = ({ property }) => {
  const dispatch = useDispatch();
  const [fetchedImages, setFetchedImages] = useState([]);

  // Fetch images
  useEffect(() => {
    if (property && property.images) {
      const fetchFirstImage = async () => {
        try {
          const firstImage = await dispatch(
            fetchImageThunk(property.images[0])
          ).unwrap();
          setFetchedImages([firstImage]);
        } catch (error) {
          console.error("Failed to fetch first image:", error);
        }
      };
      fetchFirstImage();
    }
  }, [property, dispatch]);

  if (!property) {
    return (
      <div>
        <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
          Gathering property info...
        </h2>
      </div>
    );
  }

  return (
    <motion.div
      className="bg-white overflow-hidden cursor-pointer border border-[#0A2342] shadow-md"
      whileHover={{ scale: 1.03 }}
      transition={{ duration: 0.2 }}>
      {/* Property Image and Tags */}
      <div className="relative">
        <div className="z-10 absolute top-2 right-2 bg-[#0A2342] text-white text-xs font-semibold px-2 py-1">
          {`DOM${property.listDate ? "*" : ""}: ${
            property.daysOnMarket !== null &&
            property.daysOnMarket !== undefined
              ? property.daysOnMarket
              : Math.floor(
                  (new Date() -
                    new Date(property.listDate || "2024-08-16T00:00:00.000Z")) /
                    (1000 * 60 * 60 * 24)
                )
          }`}
        </div>

        <div className="flex space-x-4">
          {fetchedImages.length > 0 ? (
            <ImageCarousel images={fetchedImages} isGridTile={true} />
          ) : (
            <img
              src="https://placehold.co/400x300"
              alt="Loading..."
              className="w-full h-auto"
            />
          )}
        </div>
      </div>

      {/* Property Details */}
      <div className="p-2">
        {/* Property Address */}
        {property.address.streetNumber &&
        property.address.streetName &&
        property.address.streetSuffix &&
        property.address.city ? (
          <p className="text-lg font-semibold text-[#0A2342]">
            {property.address.unitNumber ? (
              <>
                {`Unit ${property.address.unitNumber} - ${property.address.streetNumber} ${property.address.streetName} ${property.address.streetSuffix}, ${property.address.city}`}
              </>
            ) : (
              `${property.address.streetNumber} ${property.address.streetName} ${property.address.streetSuffix}, ${property.address.city}`
            )}
          </p>
        ) : null}

        {/* Property Type */}
        {property.details.propertyType && (
          <h3 className="text-sm text-gray-600">
            {property.details.propertyType === "Att/Row/Townhouse"
              ? "Attached / Row / Townhouse"
              : property.details.propertyType}
          </h3>
        )}

        {/* Property Details */}
        <div className="flex justify-between mt-1">
          <p className="text-sm font-bold text-[#0A2342]">
            {property.listPrice
              ? `$${parseFloat(property.listPrice).toLocaleString()}${
                  property.type ? ` - ${property.type}` : ""
                }`
              : property.type
              ? `${property.type}`
              : ""}
          </p>
        </div>

        <div className="flex justify-between mt-1">
          <p className="text-sm text-gray-600">
            {property.details.numBedrooms !== null &&
            property.details.numBedrooms !== undefined
              ? `${property.details.numBedrooms} Beds`
              : "0 Beds"}
            {" • "}
            {property.details.numBathrooms !== null &&
            property.details.numBathrooms !== undefined
              ? `${property.details.numBathrooms} Baths`
              : "0 Baths"}
            {property.details.sqft && property.details.sqft !== 0
              ? ` • ${property.details.sqft} sqft`
              : ""}
          </p>
        </div>

        {/* MLS Number and Brokerage Name */}
        <div className="flex justify-between mt-1">
          <p className="text-[11px] text-gray-600">
            {property.mlsNumber && property.mlsNumber !== 0
              ? `MLS® #${property.mlsNumber} - `
              : ""}
            {property.office && property.office.brokerageName
              ? property.office.brokerageName
              : ""}
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default PropertyGridTile;
