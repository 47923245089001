import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import * as maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

const MAPTILER_API_KEY = process.env.REACT_APP_MAPTILER_API_KEY;
const DEFAULT_LOCATION = { longitude: -79.3871, latitude: 43.6426 }; // CN Tower, Toronto

const Map = forwardRef(({ locations }, ref) => {
  const mapContainer = useRef(null);
  const mapInstance = useRef(null);

  const [mapStyle, setMapStyle] = useState("topo");

  useImperativeHandle(ref, () => ({
    flyTo: (options) => {
      if (mapInstance.current) {
        mapInstance.current.flyTo(options);
      }
    },
  }));

  useEffect(() => {
    if (locations.length === 0) return;

    const validLocation =
      locations[0].longitude && locations[0].latitude
        ? locations[0]
        : DEFAULT_LOCATION;

    const map = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/${mapStyle}/style.json?key=${MAPTILER_API_KEY}`,
      center: [validLocation.longitude, validLocation.latitude],
      minZoom: 1,
      maxZoom: 18,
      pitch: 50,
      bearing: 0,
      zoom: 14, // Initial zoom level
      // zoomDuration: 6000, // Adjust the zoom speed (duration in milliseconds)
    });

    mapInstance.current = map;

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new maplibregl.NavigationControl(), "top-right");

    // Add markers for all locations
    locations.forEach((location) => {
      const markerLocation =
        location.longitude && location.latitude ? location : DEFAULT_LOCATION;

      const address = location.address || {}; // Fallback if address is undefined

      new maplibregl.Marker()
        .setLngLat([markerLocation.longitude, markerLocation.latitude])
        .setPopup(
          new maplibregl.Popup().setHTML(`
        <p className="text-sm text-gray-600">
          ${address || "Address not available"}
        </p>
      `)
        )
        .addTo(map);
    });

    // Fit the map to show all markers
    const bounds = new maplibregl.LngLatBounds();
    locations.forEach((location) => {
      const boundLocation =
        location.longitude && location.latitude ? location : DEFAULT_LOCATION;

      bounds.extend([boundLocation.longitude, boundLocation.latitude]);
    });
    map.fitBounds(bounds, { padding: 50 });

    // Clean up on unmount
    return () => map.remove();
  }, [locations, mapStyle]);

  return (
    <div className="relative">
      {/* Map container */}
      <div ref={mapContainer} style={{ width: "100%", height: "400px" }} />

      {/* Map style buttons */}
      <div className="absolute overflow-x-auto top-2 left-2 bg-white p-1 shadow-md flex flex-wrap space-x-1 max-w-full">
        {["topo", "streets", "basic", "bright", "pastel", "hybrid"].map(
          (style) => (
            <button
              key={style}
              onClick={() => setMapStyle(style)}
              className={`text-xs px-1 py-0.5 ${
                mapStyle === style
                  ? "bg-[#0A2342] text-white"
                  : "bg-white text-[#0A2342] border border-[#0A2342]"
              }`}>
              {style.charAt(0).toUpperCase() + style.slice(1)}
            </button>
          )
        )}
      </div>
    </div>
  );
});

export default Map;
