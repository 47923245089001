import React from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
} from "@heroicons/react/24/outline";

// Custom tooltip for formatting
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const listPrice = payload[0].value;
    const soldPrice = payload[1].value;
    const daysOnMarket = payload[2]?.value || 0;
    const delta = ((soldPrice - listPrice) / listPrice) * 100;
    const isPositive = delta >= 0;

    return (
      <div className="bg-white p-2 shadow-md">
        <p className="text-gray-700 font-bold">
          {new Date(label).toLocaleString("default", {
            month: "short",
            year: "numeric",
          })}
        </p>
        <p className="text-sm text-gray-700">
          List Price: ${listPrice.toLocaleString()}
        </p>
        <p className="text-sm text-gray-700">
          Sold Price: ${soldPrice.toLocaleString()}{" "}
        </p>{" "}
        <p className="text-sm text-gray-700">Days on Market: {daysOnMarket}</p>
        <p className="text-sm text-gray-700 text-align">
          Sold vs List:{" "}
          <span className={isPositive ? "text-green-500" : "text-red-500"}>
            {Math.abs(delta).toFixed(1)}%{" "}
            {isPositive ? (
              <ArrowTrendingUpIcon className="inline-block h-5 w-5" />
            ) : (
              <ArrowTrendingDownIcon className="inline-block h-5 w-5" />
            )}
          </span>
        </p>
      </div>
    );
  }

  return null;
};

const MarketStatisticsChart = ({ property, marketStatistics }) => {
  if (!marketStatistics || !marketStatistics.statistics) {
    return <p>No market statistics available.</p>;
  }

  const { listPrice, soldPrice, daysOnMarket } = marketStatistics.statistics;

  // Prepare the chart data and filter out months with no data for listPrice, soldPrice, and daysOnMarket
  const chartData = Object.keys(listPrice.mth)
    .map((month) => {
      const listPriceData = listPrice.mth[month];
      const soldPriceData = soldPrice.mth[month] || {};
      const daysOnMarketData = daysOnMarket.mth[month] || {};

      if (
        listPriceData.count > 0 &&
        soldPriceData.count > 0 &&
        daysOnMarketData.count > 0
      ) {
        return {
          month: new Date(month),
          listPrice: listPriceData.avg || null,
          soldPrice: soldPriceData.avg || null,
          daysOnMarket: daysOnMarketData.avg || null,
        };
      }
      return null;
    })
    .filter((data) => data !== null);

  const avgSoldPrice = soldPrice.avg;
  const medSoldPrice = soldPrice.med;
  const avgListPrice = listPrice.avg;

  return (
    <div className="bg-white p-4">
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-800">
          List vs Sold Prices
        </h2>
        <div className="mt-4 text-sm text-gray-600">
          <p>
            Showing market trends for {property.details.numBedrooms} bedrooms in{" "}
            {property.address.city}
          </p>
          <p>
            Avg List Price: ${avgListPrice.toLocaleString()} | Avg Sold Price: $
            {avgSoldPrice.toLocaleString()}
            <br />
            {/* Med Sold Price: ${medSoldPrice.toLocaleString()} */}
          </p>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          data={chartData}
          margin={{ top: 0, right: 0, left: 35, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="month"
            tickLine={false}
            axisLine={true}
            tickFormatter={(month) =>
              new Date(month).toLocaleString("default", {
                month: "short",
                year: "numeric",
              })
            }
            interval={3} // Show a tick every 4 months
          />
          <YAxis
            tickLine={false}
            axisLine={true}
            tickCount={4}
            tickFormatter={(value) =>
              value > 0 ? `$${value.toLocaleString()}` : ""
            }
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            tickLine={false}
            axisLine={true}
            tickCount={4}
            tickFormatter={(value) =>
              value > 0 ? `${value.toLocaleString()}d` : ""
            }
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            formatter={(value) => {
              if (value === "listPrice") return "List Price";
              if (value === "soldPrice") return "Sold Price";
              if (value === "daysOnMarket") return "Days on Market";
              return value;
            }}
          />
          <Area
            type="natural"
            dataKey="listPrice"
            stroke="#0A2342"
            fill="#0A2342"
            fillOpacity={0.8}
            name="List Price"
            connectNulls
          />
          <Area
            type="natural"
            dataKey="soldPrice"
            stroke="#E5B13A"
            fill="#E5B13A"
            fillOpacity={0.8}
            name="Sold Price"
            connectNulls
          />
          <Bar
            yAxisId="right"
            dataKey="daysOnMarket"
            fill="#2563eb"
            name="Days on Market"
            barSize={5}
            barCategoryGap={3}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MarketStatisticsChart;
