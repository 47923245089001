import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { ChevronDown } from "lucide-react";
import {
  fetchSimilarPropertiesThunk,
  fetchSingleListingThunk,
  fetchMarketStatisticsThunk,
  fetchPropertyHistoryThunk,
  fetchImageThunk,
  fetchLocalDataThunk,
} from "../store/thunks/repliersThunk";
import Map from "../components/maplibre/Map";
import PropertyCarousel from "../components/properties/PropertyCarousel";
import ImageCarousel from "../components/properties/ImageCarousel";
import ImageMosaic from "../components/properties/ImageMosaic";
import PropertyHistory from "../components/properties/PropertyHistory";
import LocalPlaces from "../components/properties/LocalPlaces";
import MortgageCalculator from "../components/properties/MortgageCalculator";
import TaxTable from "../components/properties/TaxTable";
import MarketStatisticsChart from "../components/properties/MarketStatisticsChart";

const PropertyDetailsPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { mlsNumber, boardId } = useParams();

  const [property, setProperty] = useState(null);
  const [similarProperties, setSimilarProperties] = useState({});
  const [localData, setLocalData] = useState({});
  const [fetchedImages, setFetchedImages] = useState([]);
  const [propertyHistory, setPropertyHistory] = useState(null);
  const [marketStatistics, setMarketStatistics] = useState(null);
  const [loading, setLoading] = useState(true);

  const [activeSection, setActiveSection] = useState(null);
  const [openContact, setOpenContact] = useState(true);
  const [openHistory, setOpenHistory] = useState(true);
  const [openSimilar, setOpenSimilar] = useState(true);
  const [openFinancials, setOpenFinancials] = useState(true);
  const [openStatistics, setOpenStatistics] = useState(true);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const sectionRefs = {
    details: useRef(null),
    statistics: useRef(null),
    history: useRef(null),
    similar: useRef(null),
    financials: useRef(null),
    contact: useRef(null),
  };

  // Fetch all property-related data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading state

      try {
        // Fetch the main property data
        const propertyResponse = await dispatch(
          fetchSingleListingThunk({ mlsNumber, boardId })
        ).unwrap();
        setProperty(propertyResponse);

        // Fetch the related data in parallel using Promise.all
        const [
          similarPropertiesResponse,
          propertyHistoryResponse,
          imageResponses,
          marketStatisticsResponse,
        ] = await Promise.all([
          dispatch(
            fetchSimilarPropertiesThunk({ mlsNumber, boardId })
          ).unwrap(),
          dispatch(
            fetchPropertyHistoryThunk({
              city: propertyResponse.address.city,
              streetName: propertyResponse.address.streetName,
              streetNumber: propertyResponse.address.streetNumber,
              unitNumber: propertyResponse.address.unitNumber,
              zip: propertyResponse.address.zip,
            })
          ).unwrap(),
          Promise.all(
            propertyResponse.images.map((imageName) =>
              dispatch(fetchImageThunk(imageName)).unwrap()
            )
          ),
          dispatch(
            fetchMarketStatisticsThunk({
              city: propertyResponse.address.city,
              minBeds: propertyResponse.details.numBedrooms,
              maxBeds: propertyResponse.details.numBedrooms,
            })
          ).unwrap(),
        ]);

        setSimilarProperties(similarPropertiesResponse);
        setPropertyHistory(propertyHistoryResponse);
        setFetchedImages(imageResponses);
        setMarketStatistics(marketStatisticsResponse);
      } catch (error) {
        console.error("Failed to fetch property data:", error);
      } finally {
        setLoading(false); // Stop loading state when all calls are done
      }
    };

    fetchData();
  }, [mlsNumber, boardId, dispatch]);

  // Handle scrolling behavior
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      Object.entries(sectionRefs).forEach(([section, ref]) => {
        if (ref.current && scrollPosition >= ref.current.offsetTop) {
          setActiveSection(section);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (section) => {
    console.log("Scrolling to section:", section);
    sectionRefs[section].current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <div className="loader"></div>
          <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
            Gathering property info...
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Header */}
      <div className=" md:top-0 z-10 bg-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          <h1 className="text-2xl font-bold mb-2 text-[#0A2342]">
            {property.address.unitNumber
              ? `Unit ${property.address.unitNumber} - `
              : ""}
            {property.address.streetNumber} {property.address.streetName}{" "}
            {property.address.streetSuffix}, {property.address.city},{" "}
            {property.address.state} {property.address.zip}
          </h1>

          <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4 mb-4">
            <span className="bg-[#0A2342] text-white text-lg px-3 py-1">
              {property.listPrice
                ? `$${parseFloat(property.listPrice).toLocaleString()}${
                    property.type ? `, ${property.type}` : ""
                  }`
                : property.type
                ? `${property.type}`
                : ""}
            </span>

            <span className="text-gray-600">
              {property.details.numBedrooms !== null &&
              property.details.numBedrooms !== undefined
                ? `${property.details.numBedrooms} Beds`
                : "0 Beds"}
              {" • "}
              {property.details.numBathrooms !== null &&
              property.details.numBathrooms !== undefined
                ? `${property.details.numBathrooms} Baths`
                : "0 Baths"}
              {property.details.sqft && property.details.sqft !== 0
                ? ` • ${property.details.sqft} sqft`
                : ""}{" "}
              {property.mlsNumber && property.mlsNumber !== 0
                ? ` • MLS #${property.mlsNumber}`
                : ""}
              {property.office && property.office.brokerageName
                ? ` • ${property.office.brokerageName}`
                : ""}
            </span>
          </div>
          <div className="flex space-x-4 overflow-x-auto py-1">
            {Object.keys(sectionRefs).map((section) => (
              <motion.button
                key={section}
                onClick={() => scrollToSection(section)}
                className={`px-4 py-2 whitespace-nowrap ${
                  activeSection === section
                    ? "bg-[#0A2342] text-white"
                    : "bg-white text-[#0A2342] border border-[#0A2342]"
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}>
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </motion.button>
            ))}
          </div>
        </div>
      </div>

      {/* Body */}
      <div className="container mx-auto px-4 py-8">
        {/* Property Images */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div ref={sectionRefs.images} className="col-span-3 overflow-x-auto">
            {fetchedImages.length > 0 ? (
              window.innerWidth < 768 ? (
                <ImageCarousel images={fetchedImages} />
              ) : (
                <ImageMosaic images={fetchedImages} />
              )
            ) : (
              <img
                src="https://placehold.co/800x600"
                alt="Loading..."
                className="w-full h-auto"
              />
            )}
          </div>
        </div>

        {/* Below the fold*/}
        <div className="space-y-4">
          <>
            {/* All the details */}
            <motion.div
              ref={sectionRefs.details}
              className="bg-white p-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2 className="text-xl font-semibold mb-4 text-[#0A2342]">
                Property Details
              </h2>
              {property.details.description && (
                <p className="mb-4">
                  {property.details.description.length > 200 ? (
                    <>
                      {showFullDescription
                        ? `${property.details.description} `
                        : `${property.details.description.substring(
                            0,
                            250
                          )}... `}
                      <button
                        onClick={() =>
                          setShowFullDescription(!showFullDescription)
                        }
                        className="text-white bg-[#0A2342] px-2 py-1hover:bg-[#0A2342] hover:opacity-70 transition-opacity duration-100">
                        {showFullDescription ? " Show less" : " Show more"}
                      </button>
                    </>
                  ) : (
                    property.details.description
                  )}
                </p>
              )}

              {/* All the key-value details */}
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {property.details.propertyType && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Property Type
                    </h3>
                    <p>
                      {property.details.propertyType === "Att/Row/Townhouse"
                        ? "Attached / Row / Townhouse"
                        : property.details.propertyType}
                    </p>
                  </div>
                )}
                {property.details.numBedrooms !== null && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Bedrooms</h3>
                    <p>{property.details.numBedrooms}</p>
                  </div>
                )}
                {property.details.numBathrooms !== null && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Bathrooms</h3>
                    <p>{property.details.numBathrooms}</p>
                  </div>
                )}
                {property.details.sqft && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Square Footage
                    </h3>
                    <p>{property.details.sqft}</p>
                  </div>
                )}

                {property.details.airConditioning && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Air Conditioning
                    </h3>
                    <p>{property.details.airConditioning}</p>
                  </div>
                )}
                {property.details.heating && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Heating</h3>
                    <p>{property.details.heating}</p>
                  </div>
                )}
                {/* {property.details.elevator && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Elevator</h3>
                    <p>{property.details.elevator}</p>
                  </div>
                )} */}
                {/* {property.details.extras && (
                <div>
                  <h3 className="font-semibold text-[#0A2342]">Extras</h3>
                  <p>{property.details.extras}</p>
                </div>
              )} */}
                {property.details.garage &&
                  property.details.garage !== "None" &&
                  property.details.garage !== "N/A" && (
                    <div>
                      <h3 className="font-semibold text-[#0A2342]">Garage</h3>
                      <p>{property.details.garage}</p>
                    </div>
                  )}
                {/* {property.details.sewer && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Sewer</h3>
                    <p>{property.details.sewer}</p>
                  </div>
                )} */}
                {property.lot.depth && property.lot.width && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Lot Size</h3>
                    <p>
                      {property.lot.depth} x {property.lot.width}{" "}
                      {property.lot.measurement}
                    </p>
                  </div>
                )}
                {property.details.waterSource && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Water Source
                    </h3>
                    <p>{property.details.waterSource}</p>
                  </div>
                )}

                {property.details.driveway && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">Driveway</h3>
                    <p>{property.details.driveway}</p>
                  </div>
                )}
                {property.details.businessType && (
                  <div>
                    <h3 className="font-semibold text-[#0A2342]">
                      Business Type
                    </h3>
                    <p>{property.details.businessType}</p>
                  </div>
                )}
              </div>
            </motion.div>
            {/* Local Places and Map */}
            <div className="mb-8 flex flex-col md:flex-row gap-4">
              {/* Local Places */}
              <div className="w-full md:w-1/2">
                <LocalPlaces localData={localData} />
                {/* <MarketStatisticsChart
                  property={property}
                  marketStatistics={marketStatistics}
                /> */}
              </div>

              {/* Map */}
              <div className="w-full md:w-1/2">
                {property.map && (
                  <Map
                    locations={[
                      {
                        address: `${property.address.streetNumber} ${property.address.streetName} ${property.address.streetSuffix}, ${property.address.city}, ${property.address.state} ${property.address.zip}`,
                        longitude: property.map.longitude,
                        latitude: property.map.latitude,
                      },
                    ]}
                  />
                )}
              </div>
            </div>
            {/* Market Statistics */}
            <motion.div
              ref={sectionRefs.statistics}
              className="bg-white p-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2
                className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
                onClick={() => setOpenStatistics(!openStatistics)}>
                Market Statistics
                <ChevronDown
                  className={`ml-2 transform transition-transform ${
                    openStatistics ? "rotate-180" : "rotate-0"
                  }`}
                />
              </h2>
              {openStatistics && (
                <div>
                  <MarketStatisticsChart
                    property={property}
                    marketStatistics={marketStatistics}
                  />
                </div>
              )}
            </motion.div>
            {/* History */}
            <motion.div
              ref={sectionRefs.history}
              className="bg-white p-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2
                className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
                onClick={() => setOpenHistory(!openHistory)}>
                Property History
                <ChevronDown
                  className={`ml-2 transform transition-transform ${
                    openHistory ? "rotate-180" : "rotate-0"
                  }`}
                />
              </h2>
              {openHistory && (
                <div className="w-full">
                  {propertyHistory?.history?.length > 0 ? (
                    <PropertyHistory history={propertyHistory.history} />
                  ) : (
                    <p>No property history available.</p>
                  )}
                </div>
              )}
            </motion.div>
            {/* Similar Properties */}
            <motion.div
              ref={sectionRefs.similar}
              className={`bg-white ${openSimilar ? "" : "p-4"}`}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2
                className={`text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center ${
                  openSimilar ? "p-4" : ""
                }`}
                onClick={() => setOpenSimilar(!openSimilar)}>
                Similar Properties
                <ChevronDown
                  className={`ml-2 transform transition-transform ${
                    openSimilar ? "rotate-180" : "rotate-0"
                  }`}></ChevronDown>
              </h2>
              {openSimilar && (
                <div className="pb-2">
                  {similarProperties.similar &&
                  similarProperties.similar.length > 0 ? (
                    <PropertyCarousel properties={similarProperties.similar} />
                  ) : (
                    <p className="text-center text-md font-semibold text-[#0A2342] mb-4">
                      No similar properties available
                    </p>
                  )}
                </div>
              )}
            </motion.div>{" "}
            {/* Financial Analysis */}
            <motion.div
              ref={sectionRefs.financials}
              className="bg-white p-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2
                className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
                onClick={() => setOpenFinancials(!openFinancials)}>
                Financial Analysis
                <ChevronDown
                  className={`ml-2 transform transition-transform ${
                    openFinancials ? "rotate-180" : "rotate-0"
                  }`}
                />
              </h2>
              {openFinancials && (
                <div className="space-y-4">
                  {Object.entries(property.financials || {}).map(
                    ([key, value]) => (
                      <div
                        className="flex justify-between items-center"
                        key={key}>
                        <span>
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                        </span>
                        <span className="font-semibold text-[#0A2342]">
                          ${value ?? "N/A"}/mo
                        </span>
                      </div>
                    )
                  )}
                  <TaxTable taxes={property.taxes} />
                  <MortgageCalculator property={property} />
                </div>
              )}
            </motion.div>
            {/* Contact Form */}
            <motion.div
              ref={sectionRefs.contact}
              className="bg-white p-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2
                className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
                onClick={() => setOpenContact(!openContact)}>
                Contact Form
                <ChevronDown
                  className={`ml-2 transform transition-transform ${
                    openContact ? "rotate-180" : "rotate-0"
                  }`}
                />
              </h2>
              {openContact && (
                <form>
                  <div className="space-y-4 md:space-y-0 md:flex md:gap-4">
                    <div className="md:w-1/2 space-y-4">
                      <input
                        type="text"
                        placeholder="Name"
                        className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                        defaultValue={`${user.firstName} ${user.lastName}`}
                      />
                      <input
                        type="email"
                        placeholder="Email"
                        className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                        defaultValue={user.username}
                      />
                      <input
                        type="tel"
                        placeholder="Phone"
                        className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                      />
                    </div>
                    <div className="md:w-1/2">
                      <textarea
                        placeholder="Message"
                        rows="4"
                        className="w-full h-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"></textarea>
                    </div>
                  </div>
                  <motion.button
                    className="w-full bg-[#0A2342] text-white py-2 mt-4"
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 0.95 }}>
                    Send Message
                  </motion.button>
                </form>
              )}
            </motion.div>
          </>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailsPage;
