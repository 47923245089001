import React, { useState } from "react";
import { motion } from "framer-motion";
import PropertyGridTile from "./PropertyGridTile";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const PropertyCarousel = ({ title, properties, description }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = (event, mlsNumber, boardId) => {
    const url = `/propertydetails/${mlsNumber}/${boardId}`;
    if (event.metaKey || event.ctrlKey) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  return (
    <motion.section
      className="my-6"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      {title && (
        <div className="flex items-center mb-4 pl-4 relative">
          <h2 className="text-xl sm:text-2xl font-bold text-[#0A2342] flex items-center">
            {title}
            {description && (
              <button
                className="text-blue-500 ml-2"
                onClick={() => setShowTooltip(!showTooltip)}>
                <InformationCircleIcon className="w-5 h-5 text-[#0A2342]" />
              </button>
            )}
          </h2>
          {showTooltip && (
            <div className="text-sm absolute bottom-full mb-1 w-80 bg-white border border-gray-300 p-2 text-[#0A2342] shadow-lg z-10">
              {description}
            </div>
          )}
        </div>
      )}

      <div className="container mx-auto">
        <div className="flex overflow-x-auto space-x-4 scrollbar-show md:px-1.5">
          {properties?.length > 0 ? (
            properties.map((property, index) => (
              <div
                className={`flex-shrink-0 w-[calc(100%_-_1rem)] sm:w-1/2 md:w-1/3 lg:w-1/4 py-2 ${
                  index === 0 ? "pl-4" : ""
                } ${index === properties.length - 1 ? "pr-4" : ""}`}
                key={index}
                onClick={(event) =>
                  handleClick(event, property.mlsNumber, property.boardId)
                }>
                <PropertyGridTile property={property} />
              </div>
            ))
          ) : (
            <p className="text-gray-600">
              No properties available. Please log out and try again.
            </p>
          )}
        </div>
      </div>
    </motion.section>
  );
};

export default PropertyCarousel;
