import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, generateConfig } from "../../utils/apiConfig";

export const fetchPropertiesThunk = createAsyncThunk(
  "data/fetchProperties",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);
      const response = await api.get("/api/properties", config);
      return response.data;
    } catch (error) {
      console.error("Error fetching property data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch property data"
      );
    }
  }
);

export const fetchPersonalizedPropertiesThunk = createAsyncThunk(
  "data/fetchPersonalizedProperties",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth; // Get the token from the auth slice
      const config = generateConfig(token);

      // Fetch onboarding data using the token for authorization
      const onboardingResponse = await api.get(`/api/onboarding`, config);
      const onboardingData = onboardingResponse.data.answers; // Access the answers from the response

      // Send the entire onboarding data to the route
      const response = await api.get(`/api/personalized-properties`, {
        headers: {
          ...config.headers,
        },
        params: {
          userId: onboardingData.userId,
          location: onboardingData.location, // Pass as an array
          propertyType: onboardingData.propertyType,
          bedrooms: onboardingData.bedrooms,
          bathrooms: onboardingData.bathrooms,
          priceRange: onboardingData.priceRange,
        },
      });

      console.log("Fetched personalized properties:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching personalized properties:", error);
      return rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch personalized properties"
      );
    }
  }
);

export const fetchSingleListingThunk = createAsyncThunk(
  "data/fetchSingleListing",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);
      const response = await api.get(
        `/api/properties/${mlsNumber}?boardId=${boardId}`,
        config
      );
      console.log("Fetched single listing data:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching single listing data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch single listing data"
      );
    }
  }
);

export const fetchMarketStatisticsThunk = createAsyncThunk(
  "data/fetchMarketStatistics",
  async ({ city, minBeds, maxBeds }, { getState, rejectWithValue }) => {
    try {
      console.log("Fetching market statistics with parameters:", {
        city,
        minBeds,
        maxBeds,
      });
      const { token } = getState().auth;
      const config = generateConfig(token);
      const response = await api.get(
        `/api/market-statistics?city=${city}&minBeds=${minBeds}&maxBeds=${maxBeds}`,
        config
      );
      console.log("Fetched market statistics:", response.data);
      return response.data; // Adjust based on the response structure
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch market statistics"
      );
    }
  }
);

export const fetchPropertyHistoryThunk = createAsyncThunk(
  "data/fetchPropertyHistory",
  async (
    { city, streetName, streetNumber, unitNumber, zip },
    { getState, rejectWithValue }
  ) => {
    try {
      console.log("Fetching property history with parameters:", {
        city,
        streetName,
        streetNumber,
        unitNumber,
        zip,
      });
      const { token } = getState().auth;
      const config = generateConfig(token);
      const response = await api.get(
        `/api/properties/history?city=${city}&streetName=${streetName}&streetNumber=${streetNumber}&unitNumber=${unitNumber}&zip=${zip}`,
        config
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching property history:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch property history"
      );
    }
  }
);

export const fetchSimilarPropertiesThunk = createAsyncThunk(
  "data/fetchSimilarProperties",
  async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      // Log the parameters being sent
      console.log(
        "Fetching similar properties with mlsNumber:",
        mlsNumber,
        "and boardId:",
        boardId
      );

      const response = await api.get(
        `/api/properties/similar/${mlsNumber}?boardId=${boardId}`,
        config
      );

      console.log("Fetched similar properties successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching similar property data:",
        error.response?.data || error.message
      );
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch similar property data"
      );
    }
  }
);

export const fetchImageThunk = createAsyncThunk(
  "data/fetchImage",
  async (imageName, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);

      const response = await api.get(
        `/api/properties/image?name=${imageName}`,
        config
      );
      return response.data.image;
    } catch (error) {
      console.error("Error fetching image:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch image"
      );
    }
  }
);

export const fetchLocalDataThunk = createAsyncThunk(
  "data/fetchLocalData",
  async ({ lat, long }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth;
      const config = generateConfig(token);
      const response = await api.get(
        `/api/places?lat=${lat}&long=${long}`,
        config
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching local data:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch local data"
      );
    }
  }
);
