import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPropertiesThunk,
  fetchPersonalizedPropertiesThunk,
} from "../store/thunks/repliersThunk";
import PropertyCarousel from "../components/properties/PropertyCarousel";
import FindYourDream from "../components/FindYourDream";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth); // Get the entire auth state
  const { user } = auth;

  const [generalListings, setGeneralListings] = useState([]);
  const [personalizedListings, setPersonalizedListings] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading

      if (!user?.token) {
        console.error("Token is undefined");
        setLoading(false);
        return; // Exit if token is not available
      }

      try {
        // Fetch personalized properties
        const personalizedResult = await dispatch(
          fetchPersonalizedPropertiesThunk()
        ).unwrap();
        setPersonalizedListings(personalizedResult.listings || []);
      } catch (error) {
        console.error("Error fetching personalized properties:", error);
      }

      try {
        // Fetch general properties
        const generalResult = await dispatch(fetchPropertiesThunk()).unwrap();
        setGeneralListings(generalResult.listings || []);
      } catch (error) {
        console.error("Error fetching general properties:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchData();
  }, [dispatch, user]);

  console.log("Personalized listings", personalizedListings);
  console.log("General listings", generalListings);

  return (
    <div className="bg-gray-100 min-h-screen">
      <main className="container mx-auto py-8">
        <FindYourDream />
        {loading ? ( // Check if loading is true
          <div className="text-center">
            <div className="loader"></div>
            <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
              Gathering property info...
            </h2>
          </div>
        ) : personalizedListings.length > 0 || generalListings.length > 0 ? (
          <>
            <PropertyCarousel
              title="Personalized Recommendations"
              properties={personalizedListings}
              description="These are personalized recommendations based on your onboarding preferences."
            />
            <PropertyCarousel
              title="General Recommendations"
              properties={generalListings}
              description="These are general recommendations."
            />
            {/* <PropertyCarousel
              title="Best for Investments"
              properties={listings}
            /> */}
            {/* <PropertyCarousel title="Best for Families" properties={listings} />
            <PropertyCarousel
              title="Best for Amenities"
              properties={listings}
            /> */}
          </>
        ) : (
          <div className="text-center">
            <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
              No properties available at the moment. Try logging out and in
              again.
            </h2>
          </div>
        )}
      </main>
    </div>
  );
};

export default DashboardPage;
